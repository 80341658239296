.myBG {
  height: 100vh;
  background: url("../assets/images/bg/live.jpg");
  background-size: cover;
}

.intro {
  height: 300px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.title {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1;
  text-shadow: 2px 2px black;
  letter-spacing: 4px;
}

.trending {
  height: auto;
  background: url("../assets/images/bg/live.jpg");
  background-size: cover;
}

.superhero {
  height: auto;
  background: url("../assets/images/bg/live.jpg");
  background-size: cover;
}

.movieWrapper {
}

.movieImage {
  height: auto;
  background-color: black;
  margin: 5px;
  padding: 5px;
}

.images {
  height: 500px;
}
